import down from '../../pages/assets/img/down.png';
import React, { useState, useEffect } from 'react';
const AutoCompleteDropdown = ({ id, label, placeholder, inputValue, itemList, isDropdownOpen, dropDownToggle, onInputChange, onItemClick, onKeyPress }) => {

    const [inputFieldValue, setInputFieldValue] = useState(inputValue)
    const onInputValueChange = (value, id) => {
        setInputFieldValue(value)
        onInputChange(value, id)
    }

    const onDropDownItemClick = (value, id) => {
        setInputFieldValue(value)
        onItemClick(value, id)
    }

    useEffect(() => {
        if (inputValue != inputFieldValue) {
            setInputFieldValue(inputValue)
        }
    }, [inputValue])

    return (
        <div className={itemList.length <=1 ? "autofilterDropdowns disabledDropdown" :"autofilterDropdowns"}>
            <p className="heading">{label}</p>
            <div className="inputWithButton">
                <button
                    type="button"
                    onClick={dropDownToggle}
                    className={itemList.length <=1 ? "dropbtn disabledDropdown" : "dropbtn"}
                    disabled={itemList.length <=1}
                >
                    {' '}
                    &nbsp;{' '}
                    <img src={down} alt="down" className="down-image"></img>
                </button>

                <input
                    type="text"
                    className={itemList.length <=1 ? "dropdownelement disabledDropdown" :"dropdownelement"}
                    placeholder={placeholder}
                    id={id}
                    onChange={(e) => onInputValueChange(e.target.value, id)}
                    value={inputFieldValue}
                    disabled={itemList.length <= 1}
                />
            </div>
            {isDropdownOpen ? (
                <>
                    <div className="Autocomplete">
                        {
                            itemList &&
                            itemList.length > 0 &&
                            itemList.map((item, index) => (
                                <div
                                    key={index + 'l'}
                                    className="dropdown-each"
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={(event) => onKeyPress(event, item.value)}
                                    onClick={(event) => onDropDownItemClick(item.value, id)} >
                                    {item.value}
                                </div>
                            ))}
                    </div>
                </>
            ) : (
                ''
            )}
        </div>
    )
}

export default AutoCompleteDropdown;