import React, { useState, useEffect, useContext } from 'react';
import { USBGrid, USBColumn, USBSelect, USBSearchInput } from '@usb-shield/components-react';
import USBButton from '@usb-inner-src/react-button';
import UsbTableData from '../../../components/utilities/UsbTable';
import { endpoints } from '../../../../config/connections';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import Loader from '../../../components/utilities/Loader';
import '@usb-shield/react-forms-base/dist/library/styles/index.css';
import './vendor-report.css';
import callApi from '../../../components/utilities/callApi';
import useUserRole from '../../../components/Hooks/use-UserRole';
import _noaccess from '../../../pages/_noaccess';
import { USBFormsHelperText } from '@usb-shield/react-forms-base';
import {downloadReport} from '../../../components/utilities/Utils';

const Vendor = () => {
  const [vendorCompanyNameList, setVendorCompanyNameList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [vendorListImmutable, setVendorListImmutable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [selections, setSelections] = useState("");
  const [keyword, setKeyword] = useState("");
  const getFilterData = async () => {
    setLoading(true);
    const url = `${endpoints.apigee}/document-management/coreservice/user-management/v1/vendors/details`;
    try{
      const response = await callApi.getApiData(url);
      if(response){
        setResponseData(response?.data);
        filterResponse(response?.data);
      }
    }catch{
       setLoading(false);
    }
   
  };

  const getVendorNames = async (selectionsParam) => {
    const url = `${endpoints.apigee}/document-management/coreservice/user-management/v1/vendors/access?vendorNumber=${selectionsParam}`;
    try{
      const response = await callApi.getApiData(url);
      if(response){
        setVendorList(response.data);
          setVendorListImmutable(response.data);
          setLoading(false);
      }
    }catch(e){
      setVendorList([])
      setVendorListImmutable([])
      setLoading(false)
    }
  };

  const filterResponse = (response) => {
    const parentCompanyTemp = [];
    response.forEach((element) => {
      parentCompanyTemp.push(element.vendorName);
    });
    setVendorCompanyNameList(formatFilterData(parentCompanyTemp, 0));
    setLoading(false);
  };

  const handleSearch = (value) => {
    setKeyword(value)
    if (value.length > 0) {
      setVendorList(vendorListImmutable.filter(obj =>
         obj.companyName.toLowerCase().includes(value.toLowerCase())||
         obj.legalEntity.toLowerCase().includes(value.toLowerCase())||
         obj.deals.toLowerCase().includes(value.toLowerCase())||
         obj.role.toLowerCase().includes(value.toLowerCase())||
         obj.documentType.toLowerCase().includes(value.toLowerCase())
         ));
    }
    else {
      setVendorList(vendorListImmutable);
    }
  }

  const onFilterChange = (event, filterName) => {
    setKeyword('')
    const eventValue = event?.target?.value;
    if (event.errorCode != 224) {
      setLoading(true);
      const data = responseData[eventValue]?.vendorNumber;
      const companyname = responseData[eventValue]?.vendorName;
      getVendorNames(data);
      setSelections(companyname);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const formatFilterData = (list, indicator) => {
    const newData = [];
    if (indicator == 0) {
      // for parent company
      list.forEach((element, index) => {
        if (element!==null && element!==undefined) {
          newData.push({ value: index, content: element }); 
        }
      });
    }
    // console.log(list, 'list');
    const sortedData = newData.sort((a, b) =>
      a.content.localeCompare(b.content)
    );
    sortedData.unshift({ value: '', content: 'Select' });
    return sortedData;
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const tempUserRole = useUserRole();
  const columns = [
    {
      headerName: 'Company Details',
      fieldFor: 'first',
      dataType: 'node',
      type: 'default',
      element : (banker)=>{
        return <>
        <strong>{banker.companyName}</strong><br />
        {banker.legalEntity.split("|") == '' ? 0 : banker.legalEntity.split("|").length}{ banker.legalEntity.split("|").length > 1 ? ' Legal Entities' : ' Legal Entity'}<br />
        {banker.deals.split("|") == '' ? 0 : banker.deals.split("|").length }{ banker.deals.split("|").length > 1 ? ' Deals' : ' Deal'}<br />
        {banker.documentType.split("|") == '' ? 0 : banker.documentType.split("|").length }{ banker.documentType.split("|").length > 1 ? ' Document Types' : ' Document Type'}<br />
        
      </>
      }
    },
    {
      headerName: 'Role',
      key : 'role',
      fieldFor: 'second',
      dataType: 'node',
      type: 'default',
    },
    {
      headerName: 'Legal Entity',
      fieldFor: 'third',
      dataType: 'node',
      type: 'default',
      element : (banker)=>{
        return <>
        <span>
          {banker.legalEntity.split("|").map((item, index) => (
            <>
            <strong>{item.split(":")[0]}</strong>{item.split(":")[1]}
             
              {banker.legalEntity.split("|").length > (index + 1) ? (<hr />) : null}
            </>
          ))}
        </span>
      </>
      }
    },
    {
      headerName: 'Deals',
      fieldFor: 'fourth',
      dataType: 'node',
      type: 'default',
      element : (banker)=>{
        return <>
        <span>
          {banker.deals.split("|").map((item, index) => (
            <>
              <strong>{item.split(":")[0]}</strong>{item.split(":")[1]}
              {banker.deals.split("|").length > (index + 1) ? (<hr />) : null}
            </>
          ))}
        </span>
      </>
      }
    },
    {
      headerName: 'Document Type',
      fieldFor: 'fifth',
      dataType: 'node',
      type: 'default',
      element : (banker)=>{
        return <>
        <span>
          {banker?.documentType?.split("|").map((item, index) => (
            <>
              {item.length > 1 ? <li>{item}</li> : ''}
            </>
          ))}
        </span>
      </>
      }
    },
  ]
 const filterSelections = `Filter selections : Vendor Company Granted Access = ${selections} ` + '';
 const columnName = {
              ' ': 'COMPANY DETAILS',
              '  ': 'Role',
              '   ': 'LEGAL ENTITY',
              '    ': 'DEALS',
              '     ': 'DOCUMENT TYPE'

 };
 const headersFields = [
        'companyName',
        'role',
        'legalEntity',
        'deals',
        'documentType'
 ]    

  return (
    tempUserRole && tempUserRole != "employee" ? <_noaccess /> :
      <>
        <USBGrid addClasses="content-frame">
          <USBColumn largeSpan={8}>
            <USBBreadcrumb
              id="reports"
              text="Reports"
              breadcrumbs={[
                { id: 1, text: 'Reports', href: '/Reports/VendorCompanyFilter' },
                { id: 2, text: 'Vendor Access', href: '' },
              ]}
            />
          </USBColumn>
          <USBColumn largeSpan={8}></USBColumn>
        </USBGrid>
        <USBGrid addClasses="content-frame content-bg-vendor-access">
          {loading ? <Loader /> : null}
          <USBColumn largeSpan={16}>
            <h1 className="heading-h1">Report: Vendor Access</h1>

            <div className="sorting-inputs-vendor-access">
              <div className="filter-dropdowns-vendor-access">
                {vendorCompanyNameList.length > 0 ? (
                  <div>
                    <USBSelect
                      id="companyName"
                      inputName="companyName"
                      name="vendorParentCompanyName"
                      initValue={''}
                      labelText="Vendor Company Granted Access"
                      optionsArray={vendorCompanyNameList}
                      handleChange={(e) => {
                        onFilterChange(e, 'vendorNumber');
                      }}
                      errorMessages={{ 224: 'Please select an option' }}
                    />
                    <USBFormsHelperText labelFor="business-structure">
                      {selections || ""}
                    </USBFormsHelperText>
                  </div>
                ) : (
                  null
                )}
              </div>
              <div>
                <USBButton
                  id="downloadReport"
                  name="downloadReport"
                  variant="primary"
                  handleClick={() => downloadReport(vendorList,filterSelections,columnName,headersFields,'Vendor Access')}
                >
                  Download Report
                </USBButton>
              </div>
              {vendorCompanyNameList.length > 0 ? (
                <div >
                  <USBButton
                    type="button"
                    size="small"
                    id="clearFilterVendorAccess"
                    name="clearfilter"
                    handleClick={() => {
                      setLoading(true);
                      setVendorCompanyNameList([]);
                      setSelections("");
                      setVendorList([]);
                      setVendorListImmutable([]);
                      getFilterData();
                      setLoading(false);
                    }}
                  >
                    Clear filters
                  </USBButton>
                </div>
              ) : (
                null
              )}
            </div>
            {vendorListImmutable.length > 0 ? (
              <div className="page-content-vendor-access ">
                <div>
                  <input
                    type="search"
                    className='input-search'
                    id='input_searchData'
                    placeholder="Search by company name, legal entities, deals, role or document types."
                    value={keyword}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  <br /><br />
                  {vendorList.length > 0 ? (vendorList.length + " records found.") : "No Records Found"}
                  <br />

                </div>
                {vendorList.length > 0 ? (
                  <div className="vendor-access-page-content">
                  <UsbTableData
                  data={vendorList}
                  columns={columns}
                  />
                  </div>
                ) : (
                  null
                )}
              </div>
            ) : (
              null
            )}
          </USBColumn>
        </USBGrid>
      </>
  );
};

export default Vendor;
