import axios from 'axios';
import { endpoints } from '../../../config/connections';

export async function getRequirementsRequirements({ setRequirements,setTotalCount , setCategories,setStatus,reqCategory,reqStatus,reqDealId,currentPage,requirementsPerPage }) {
  // if(reqCategory==='Invoice' && (reqStatus !=='Read')){
  //   reqStatus="New"
  // } 
  // console.log(reqStatus)
  const url = `${endpoints.apigee}/document-management/cdc/requirements/v1/employee/requirements?category=${reqCategory}&status=${reqStatus}&dealId=${reqDealId}&page=${currentPage-1}&pageSize=${requirementsPerPage}`;
  const https = getAxiosInstance();
  const response = await https.get(url);
  if (response?.status === 200) {
    setRequirements(response?.data?.documents);
    setTotalCount(response?.data?.totalCount);
    setCategories(response?.data?.distinct?.map(item => ({
      label: item,
      value: item
  })));
  } else {
    setStatus((state) => ({
      ...state,
      loading: false,
      error: 'Failed to get Requirements requirements.',
    }));
  }
}



export function getAxiosInstance() {
  const loggedInUser = sessionStorage.getItem('loggedUser');
  const correlationId = sessionStorage.getItem('correlationId');
  const at = sessionStorage.getItem('at');
  const jwt = sessionStorage.getItem('tok');
  const userRole = sessionStorage.getItem('userrole');
  const assignedUser = sessionStorage.getItem('assignedUser');
  const remoteUser = sessionStorage.getItem('remote_user');

  return axios.create({
    headers: {
      Accept: 'application/json',
      'Correlation-ID': `${loggedInUser} ${correlationId}`,
      'Client-Application': 'CDC',
      Authorization: `Bearer ${at}`,
      jwt: `${jwt}`,
      'user-id': remoteUser ? JSON.parse(remoteUser).userID : `${assignedUser}`,
    },
  });
}

// Constants

export function isEmployee() {
  const userRole = sessionStorage.getItem('userrole');
  return userRole === 'employee';
}

// function getSessionStorageItem(key, defaultValue) {
//   if (typeof sessionStorage !== 'undefined') {
//       return JSON.parse(sessionStorage.getItem(key)) || defaultValue;
//   } else {
//       return defaultValue;
//   }
// }

export function getDefaultFilters() {
  return { status: { label: 'Submitted', value: 'Submitted' } }
  //  const defaultFilters={ status: { label: 'Submitted', value: 'Submitted' }, category: { label: 'All', value: '' } }
  //  return getSessionStorageItem('defaultFilters', defaultFilters);
}

// returns value in key by checking the obj recursively and if it encounters an array of objects then it
// return first element and parses in that
export function getValueByNestedKey(obj, key) {
  const keys = key.split('.');
  return keys.reduce((nested, nestedKey) => {
    if (Array.isArray(nested?.[nestedKey])) {
      return nested && nested[nestedKey]?.[0];
    }
    return nested && nested[nestedKey];
  }, obj);
}
