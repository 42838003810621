import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Dropdown } from 'primereact/dropdown';
import USBDatePicker from '@usb-shield/react-date-picker';
import down from '../assets/img/down.png';
import { defaultCalendarSettings } from '@usb-shield/react-calendar';
import { endpoints } from '../../../config/connections';
import callApi from '../../components/utilities/callApi';
import { Checkbox } from 'primereact/checkbox';
import {
  USBGrid,
  USBColumn,
  USBButton,
  USBModal,
  USBModalHeader,
  USBModalBody,
  USBModalFooter,
  USBModalCloseIcon,
  USBSelect,
} from '@usb-shield/components-react';
import USBTable from '@usb-inner-src/react-table';
import moment from 'moment';
// import Router from 'next/router'
import { useHistory } from 'react-router-dom';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import { setUserRole, getQS } from '../../components/utilities/Utils';
import Loader from '../../components/utilities/Loader';
import { DashboardContext } from '../context/DashboardContext';
import { DocContext } from '../context/DocContext';
import {ExportToExcel} from '../../components/utilities/ExportToExcel';
import './cbPortfolioParent.css';
import actionlogo from '../../assets/images/rightArrow.png';
import dateAlertIcon from '../../../assets/img/dateAlert.png';
import portalIndicator from '../../../assets/img/portal.png';
import searchIcon from '../../../assets/img/search.png';
import useUserRole from '../../components/Hooks/use-UserRole';

export const reuseFilterFn = (reportResultsFull, activeFilters) => {
  let initialData = reportResultsFull;
  if (activeFilters.reportingEntity !== '') {
    initialData = initialData.filter(
      (obj) => obj.docType === activeFilters.reportingEntity
    );
  }
  if (activeFilters.parentCompany !== '') {
    initialData = initialData.filter(
      (obj) => obj.metadata.parentCompanyName === activeFilters.parentCompany
    );
  }

  if (activeFilters.asOfDate !== '') {
    initialData = initialData.filter(
      (obj) => obj.asOfDate == activeFilters?.asOfDate
    );
  }

  if (activeFilters.submittedDate !== '') {
    initialData = initialData.filter(
      (obj) =>
      obj.submittedDate && moment(obj.submittedDate).format('MM/DD/YYYY') === activeFilters?.submittedDate
    );
  }

  if (activeFilters.requirementName !== '') {
    initialData = initialData.filter(
      (obj) => obj.category === activeFilters.requirementName
    );
  }
  if (activeFilters.dealName !== '') {
    initialData = initialData.filter(
      (obj) => obj.metadata.dealName === activeFilters.dealName
    );
  }
  if (activeFilters.dealId !== '') {
    initialData = initialData.filter(
      (obj) => obj.metadata.dealId === activeFilters.dealId
    );
  }
  if (activeFilters.status !== '' && activeFilters.status !== 'Status') {
    initialData = initialData.filter(
      (obj) => obj.status === activeFilters.status
    );
  }
  if (activeFilters.following !== '' && activeFilters.following !== 'Follow') {
    const followvalue = activeFilters.following == 'true' ? true : false;
    initialData = initialData.filter((obj) => obj.following === followvalue);
  }
  if (activeFilters.syndicationFundName !== '') {
    initialData = initialData.filter(
      (obj) =>
        obj.metadata.syndicationFundName === activeFilters.syndicationFundName
    );
  }
  // Data after selecting parent company
  return initialData;
};

export const globalSearchFn = (value, results,dashboardData) => {
  let temp = [];
  if (value !== '') {
    results.forEach((item) => {
      if (
        (
          item.dueDate !== null &&
          item.dueDate !== '' &&
          item?.dueDate?.includes(value) ) ||
        (
          item.category !== null &&
          item.category !== '' &&
          item?.category?.toLowerCase().includes(value.toLowerCase()) ) ||
        (
          item.metadata.reportingEntity !== null &&
          item.metadata.reportingEntity !== '' &&
          item?.metadata?.reportingEntity.toLowerCase().includes(value.toLowerCase())) ||
        (
          item.metadata.dealName !== null &&
          item.metadata.dealName !== '' &&
          item?.metadata?.dealName.toLowerCase().includes(value.toLowerCase())) ||
        (
          item.metadata.parentCompanyName !== null &&
          item.metadata.parentCompanyName !== '' &&
          item?.metadata?.parentCompanyName.toLowerCase().includes(value.toLowerCase())) ||
        (
          item.asOfDate !== null &&
          item.asOfDate !== '' &&
          item?.asOfDate.includes(value)) ||
        ((dashboardData.status=="Submitted") &&
          item.submittedDate !== null &&
          item.submittedDate !== '' &&
          item?.submittedDate.includes(value)) ||
        (
          item.status !== null &&
          item.status !== '' &&
          item?.status.toLowerCase().includes(value.toLowerCase())) ||
        (
          item.metadata.syndicationFundName !== null &&
          item.metadata.syndicationFundName !== '' &&
          item?.metadata?.syndicationFundName.toLowerCase().includes(value.toLowerCase())) ||
        (
          item.metadata.dealId !== null &&
          item.metadata.dealId &&
          item.metadata.dealId !== '' &&
          item?.metadata?.dealId.toLowerCase().includes(value.toLowerCase()))
      ) {
        temp.push(item);
      }
    });
  } else {
    temp = results;
  }
  // console.log(temp, 'search');
  return temp;
};

export const reuseSearchFn = (value, searchValue, results) => {
  let temp = [];
  if (value !== '') {
    results.forEach((item) => {
      if (
        ((searchValue === 'documentType' || searchValue === '') &&
          item.category !== null &&
          item.category !== '' &&
          item.category.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        ((searchValue === 'legalEntity' || searchValue === '') &&
          item.metadata.reportingEntity !== null &&
          item.metadata.reportingEntity !== '' &&
          item.metadata.reportingEntity
            .toLowerCase()
            .indexOf(value.toLowerCase()) > -1) ||
        ((searchValue === 'dealName' || searchValue === '') &&
          item.metadata.dealName !== null &&
          item.metadata.dealName !== '' &&
          item.metadata.dealName.toLowerCase().indexOf(value.toLowerCase()) >
            -1) ||
        ((searchValue === 'dealId' || searchValue === '') &&
          item.metadata.dealId !== null &&
          item.metadata.dealId !== '' &&
          item.metadata.dealId.startsWith(value)) ||
        ((searchValue === 'parentCompany' || searchValue === '') &&
          item.metadata.parentCompanyName !== null &&
          item.metadata.parentCompanyName !== '' &&
          item.metadata.parentCompanyName
            .toLowerCase()
            .indexOf(value.toLowerCase()) > -1) ||
        ((searchValue === 'asOfDate' || searchValue === '') &&
          item.asOfDate !== null &&
          item.asOfDate !== '' &&
          item.asOfDate==value) ||
        ((searchValue === 'submittedDate' || searchValue === '') &&
          item.submittedDate !== null &&
          item.submittedDate !== '' &&
          moment(item.submittedDate).format('MM/DD/YYYY')==value) ||
        ((searchValue === 'status' || searchValue === '') &&
          item.status !== null &&
          item.status !== '' &&
          item.status.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
        ((searchValue === 'syndicationFundName' || searchValue === '') &&
          item.metadata.syndicationFundName !== null &&
          item.metadata.syndicationFundName !== '' &&
          item.metadata.syndicationFundName
            .toLowerCase()
            .indexOf(value.toLowerCase()) > -1) ||
        ((searchValue === 'dealNumber' || searchValue === '') &&
          item.metadata.dealId !== null &&
          item.metadata.dealId &&
          item.metadata.dealId !== '' &&
          item.metadata.dealId.startsWith(value))
      ) {
        temp.push(item);
      }
    });
  } else {
    temp = results;
  }
  // console.log(temp, 'search');
  return temp;
};

export const reuseSortingFn = (sortValue, results) => {
  let tmpResults = results;
  if (sortValue === 'fundDesc') {
    tmpResults.sort((a, b) => {
      const nameA =
        a.metadata.syndicationFundName !== null
          ? a.metadata.syndicationFundName.toLowerCase()
          : '';
      const nameB =
        b.metadata.syndicationFundName !== null
          ? b.metadata.syndicationFundName.toLowerCase()
          : '';
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortValue === 'fundAsc') {
    const emptyResults = results.filter((n) => {
      return (
        n.metadata.syndicationFundName === null ||
        n.metadata.syndicationFundName === ''
      );
    });
    const nonEmptyResults = results.filter((n) => {
      return (
        n.metadata.syndicationFundName !== null &&
        n.metadata.syndicationFundName !== ''
      );
    });
    nonEmptyResults.sort((a, b) => {
      const nameA =
        a.metadata.syndicationFundName !== null
          ? a.metadata.syndicationFundName.toLowerCase()
          : '';
      const nameB =
        b.metadata.syndicationFundName !== null
          ? b.metadata.syndicationFundName.toLowerCase()
          : '';
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    tmpResults = [...nonEmptyResults, ...emptyResults];
  }
  if (sortValue === 'asOfDateDesc') {
    const nonAsOfDateResults = results.filter((n) => {
      return n.status.toLowerCase() !== 'asOfDate';
    });
    const asOfDateResults = results.filter((n) => {
      return n.status.toLowerCase() === 'asOfDate';
    });
    asOfDateResults.sort((a, b) => {
      const key1 = new Date(a.asofDate);
      const key2 = new Date(b.asofDate);
      if (key1 > key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });
    tmpResults = [...asOfDateResults, ...nonAsOfDateResults];
  }

  if (sortValue === 'asOfDateAsc') {
    const nonAsOfDateResults = results.filter((n) => {
      return n.status.toLowerCase() !== 'asofDate';
    });
    const asOfDateResults = results.filter((n) => {
      return n.status.toLowerCase() === 'asofDate';
    });
    asOfDateResults.sort((a, b) => {
      const key1 = new Date(a.asofDate);
      const key2 = new Date(b.asofDate);
      if (key1 < key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });
    tmpResults = [...asOfDateResults, ...nonAsOfDateResults];
  }

  if (sortValue === 'submittedDesc') {
    const nonSubmittedResults = results.filter((n) => {
      return n.status.toLowerCase() !== 'submitted';
    });
    const submittedResults = results.filter((n) => {
      return n.status.toLowerCase() === 'submitted';
    });
    submittedResults.sort((a, b) => {
      const key1 = new Date(a.submittedDate);
      const key2 = new Date(b.submittedDate);
      if (key1 > key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });
    tmpResults = [...submittedResults, ...nonSubmittedResults];
  }
  if (sortValue === 'submittedAsc') {
    const nonSubmittedResults = results.filter((n) => {
      return n.status.toLowerCase() !== 'submitted';
    });
    const submittedResults = results.filter((n) => {
      return n.status.toLowerCase() === 'submitted';
    });
    submittedResults.sort((a, b) => {
      const key1 = new Date(a.submittedDate);
      const key2 = new Date(b.submittedDate);
      if (key1 < key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });
    tmpResults = [...submittedResults, ...nonSubmittedResults];
  }

  if (sortValue === 'dateDesc') {
    tmpResults = results.sort((a, b) => {
      const key1 = new Date(a.dueDate);
      const key2 = new Date(b.dueDate);
      if (key1 > key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });
  }
  if (sortValue === 'dateAsc') {
    tmpResults = results.sort((a, b) => {
      const key1 = new Date(a.dueDate);
      const key2 = new Date(b.dueDate);
      if (key1 < key2) {
        return -1;
      }
      if (key1 === key2) {
        return 0;
      }
      return 1;
    });
  }
  if (sortValue === 'dealDesc') {
    tmpResults.sort((a, b) => {
      const nameA = a.metadata.dealName.toLowerCase();
      const nameB = b.metadata.dealName.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortValue === 'dealAsc') {
    tmpResults.sort((a, b) => {
      const nameA = a.metadata.dealName.toLowerCase();
      const nameB = b.metadata.dealName.toLowerCase();
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortValue === 'legalDesc') {
    tmpResults.sort((a, b) => {
      const nameA = a.metadata.parentCompanyName.toLowerCase();
      const nameB = b.metadata.parentCompanyName.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortValue === 'legalAsc') {
    tmpResults.sort((a, b) => {
      const nameA = a.metadata.parentCompanyName.toLowerCase();
      const nameB = b.metadata.parentCompanyName.toLowerCase();
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortValue === 'legalDesc') {
    tmpResults.sort((a, b) => {
      const nameA = a.submittedDate;
      const nameB = b.submittedDate;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortValue === 'legalAsc') {
    tmpResults.sort((a, b) => {
      const nameA = a.submittedDate;
      const nameB = b.submittedDate;
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortValue === 'docDesc') {
    tmpResults.sort((a, b) => {
      const nameA = a.category.toLowerCase();
      const nameB = b.category.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  if (sortValue === 'docAsc') {
    tmpResults.sort((a, b) => {
      const nameA = a.category.toLowerCase();
      const nameB = b.category.toLowerCase();
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });
  }
  return tmpResults;
};


export const handleSearchChange = (
  value,
  reportResultsFull,
  searchValue,
  setReportResults,
  setDashboardData,
  dashboardData,
  setTotalPages,
  setTotalResults,
  activeFilters,
  sortByValue
) => {
  const filteredData = reuseFilterFn(reportResultsFull, activeFilters);
  const searchedData = reuseSearchFn(value,searchValue ,filteredData);
  const temp = reuseSortingFn(sortByValue, searchedData);
  // console.log(filteredData,searchedData,temp,"search")
  setReportResults(temp);
  setTotalResults(temp.length);
  setTotalPages(Math.ceil(temp.length / 10));
  const dashboardDataTemp = dashboardData;
  dashboardDataTemp.search = value;
  setDashboardData(dashboardDataTemp);
  // filterAllClear(setDashboardData)
};

export const sortFilteredData = (
  e,
  reportResults,
  setReportResults,
  setSortByValue,
  setTotalPages,
  setTotalResults
) => {
  const reportResultsTemp = reuseSortingFn(e.target.value, reportResults);
  setReportResults(reportResultsTemp);
  setTotalPages(Math.ceil(reportResultsTemp.length / 10));
  setTotalResults(reportResultsTemp.length);
  setSortByValue(e.target.value);
};

/**
 * A callback function that handles the changes from a USBSelect component.
 *
 * @param {React.ChangeEvent<HTMLSelectElement>} changeEvent The change event from the USBSelect component.
 * @param {string} column The name of the column being filtered.
 * @param {import('../context/DashboardContext').ReportingFilters} activeFilters
 * @param {*} setActiveFilters A dispatch action
 * @param {[import('../context/DashboardContext').ReportingData]} reportResultsFull
 * @param {*} setReportResults A dispatch action
 * @param {*} setDashboardData A dispatch action
 * @param {*} dashboardData The dashboard data.
 * @param {string} sortByValue The column name and direction to sort the data.
 * @param {*} setTotalPages A dispatch action
 * @param {*} setTotalResults A dispatch action
 * @param {string} searchValue The current search value.
 * @param {*} loadFilterS The loadFilterS function in the dashboard context.
 * @param {*} emptyFilters The emptyFilters function in the dashboard context.
 * @param {*} getCBAPIResponse The getCBAPIResponse function in the dashboard context.
 */
export const filterData = (
  changeEvent,
  column,
  activeFilters,
  setActiveFilters,
  reportResultsFull,
  setReportResults,
  setDashboardData,
  dashboardData,
  sortByValue,
  setTotalPages,
  setTotalResults,
  searchValue,
  loadFilterS,
  emptyFilters,
  getCBAPIResponse,
  setRefreshUI
) => {
  const activeFiltersTemp = activeFilters;
  // console.log("calledfilter","indi");
  // const selectedValue = changeEvent?.inputValue

  const selectedValue = changeEvent?.inputValue
    ? changeEvent?.inputValue
    : changeEvent?.target?.value;

  if (activeFiltersTemp[column] === selectedValue) {
    return;
  }

  if (column === 'requirementName') {
    activeFiltersTemp.requirementName = selectedValue;
  }
  if (column === 'reportingEntity') {
    activeFiltersTemp.reportingEntity = changeEvent;
    activeFiltersTemp.dealName = '';
    activeFiltersTemp.syndicationFundName = '';
  }
  if (column === 'parentCompany') {
    activeFiltersTemp.parentCompany = changeEvent;
    activeFiltersTemp.reportingEntity = '';
    activeFiltersTemp.dealName = '';
    activeFiltersTemp.syndicationFundName = '';
  }
  if (column === 'asOfDate' && (selectedValue?.length == 10 || selectedValue == undefined)) {
    activeFiltersTemp.asOfDate = selectedValue||"";
  }
  if (column === 'submittedDate' && (selectedValue?.length == 10 || selectedValue == undefined)) {
    activeFiltersTemp.submittedDate = selectedValue||"";;
  }
  if (column === 'dealName') {
    activeFiltersTemp.dealName = changeEvent;
  }
  if (column === 'dealId') {
    activeFiltersTemp.dealId = changeEvent;
  }
  if (column === 'status') {
    activeFiltersTemp.status = selectedValue;
  }
  if (column === 'followCheck') {
    activeFiltersTemp.following = selectedValue;
  }
  if (column === 'syndicationFundName') {
    activeFiltersTemp.syndicationFundName = changeEvent;
  }

  setActiveFilters(activeFiltersTemp);
  sessionStorage.setItem('sessionFilters', JSON.stringify(activeFiltersTemp));
  setDashboardData({
    search: dashboardData.search,
    dealNameValue: activeFiltersTemp.dealName,
    dealIdValue: activeFiltersTemp.dealId,
    reportingEntityValue: activeFiltersTemp.reportingEntity,
    parentCompanyValue: activeFiltersTemp.parentCompany,
    asOfDateValue: activeFiltersTemp.asOfDate,
    submittedDateValue: activeFiltersTemp.submittedDate,
    requirementNameValue: activeFiltersTemp.requirementName,
    statusValue: activeFiltersTemp.status,
    syndicationfundValue: activeFiltersTemp.syndicationFundName,
    followValue: activeFilters.following,
  });
  const remote_customer = JSON.parse(sessionStorage.getItem('remote_user'))
    ? JSON.parse(sessionStorage.getItem('remote_user')).userID
    : null;
  const view_as = sessionStorage.getItem('view_as')
    ? `${JSON.parse(sessionStorage.getItem('view_as')).bankerId}@cdcemp`
    : null;
  // const initialFiltered = initialFilter(reportResultsFull)
  if (column === 'status' || column === 'requirementName') {
    if (remote_customer) {
      getCBAPIResponse(remote_customer, activeFiltersTemp.status, true);
    } else if (view_as) {
      getCBAPIResponse(view_as, activeFiltersTemp.status);
    } else {
      getCBAPIResponse(
        sessionStorage.getItem('assignedUser'),
        activeFiltersTemp.status
      );
    }
  } else {
    const filteredResults = reuseFilterFn(reportResultsFull, activeFiltersTemp);
    // console.log(filteredResults,"hello")
    const searchedData = reuseSearchFn(
      dashboardData.search,
      searchValue,
      filteredResults
    );
    const sortedData = reuseSortingFn(sortByValue, searchedData);
    setReportResults(sortedData);
    setTotalPages(Math.ceil(sortedData.length / 10));
    setTotalResults(sortedData.length);
    if (sortedData.length > 0) {
      loadFilterS(sortedData, {'column':column,'isCB':true});
      setRefreshUI(false)
    } else {
      emptyFilters(activeFiltersTemp);
      setRefreshUI(false)
    }
  }
};

export const tblContentTemplate = (item, filterStatus, setActionsDrop) => {
  const docDate = item.dueDate;
  const docdueDate = moment(item.dueDate, 'YYYYMMDD').format('YYYYMMDD');
  let dateHtml;
  let dueHtml = '';
 
  const disabledID = sessionStorage.getItem('userrole') === 'employee' && !sessionStorage.getItem('remote_user') && sessionStorage.getItem('userData') && JSON.parse(sessionStorage.getItem('userData')).emailId ? false : true; 
 
  const today = moment(new Date(), 'YYYYMMDD').format('YYYYMMDD');
  if (docDate != null) {
    const days = moment(docdueDate, 'YYYYMMDD').diff(
      moment(today, 'YYYYMMDD'),
      'days'
    );
    if (days === 0 && item.status === 'Not Submitted') {
      dueHtml = <div className="date-details">Due today</div>;
    } else if (days < 0 && item.status === 'Not Submitted') {
      dueHtml = (
        <div className="font-red date-details">
          <span className="date-alert">
            <img src={dateAlertIcon} />
          </span>
          Past due
        </div>
      );
    } else if (days > 0 && item.status !== 'Completed') {
      dueHtml = (
        <div className="date-details">
          Due in&nbsp;
          {days}
          &nbsp;
          {days > 1 ? 'days' : 'day'}
        </div>
      );
    }
    dateHtml = (
      <div>
        {moment(docdueDate).format('MM/DD/YYYY')}
        <div>{dueHtml}</div>
      </div>
    );
  } else dateHtml = '';

  let dealNametext = '';
  let dealNumberText;
  if (
    (item.metadata.syndicationFundName === '' ||
      item.metadata.syndicationFundName === null) &&
    (item.metadata.dealName === '' || item.metadata.dealName === null)
  ) {
    dealNametext = 'Not applicable';
    dealNumberText = '';
  } else if (
    item.metadata.syndicationFundName === '' ||
    item.metadata.syndicationFundName === null
  ) {
    dealNametext = item.metadata.dealName;
    dealNumberText = item.metadata.dealId;
  } else if (
    (item.metadata.dealName === '' || item.metadata.dealName === null) &&
    item.metadata.showSyndicationFundInd === true
  ) {
    dealNametext = item.metadata.syndicationFundName;
    dealNumberText = item.metadata.syndicationFund;
  } else if (
    (item.metadata.dealName === '' || item.metadata.dealName === null) &&
    item.metadata.showSyndicationFundInd === false
  ) {
    dealNametext = 'Not applicable';
    dealNumberText = '';
  } else if (
    sessionStorage.getItem('userrole') !== 'employee' &&
    item.metadata.showSyndicationFundInd === false
  ) {
    dealNametext = item.metadata.dealName;
    dealNumberText = item.metadata.dealId;
  } else {
    dealNametext = `${item.metadata.dealName} | ${item.metadata.syndicationFundName}`;
    dealNumberText = item.metadata.dealId;
  }

  const legalEntityHtml = item.metadata.reportingEntity;
  const parentCompanyHtml = item.metadata.parentCompanyName;

  let docCategory = '';
  if (item.category === null) {
    docCategory = <div className="empty-div" />;
  } else docCategory = item.category;
  const docRequirement = item.requirementName;

  const asofDate = item.asOfDate;
  let asofdateHtml;
  if (asofDate != null) asofdateHtml = <div>{asofDate}</div>;
  else asofdateHtml = '';

  const receivedDate = item.submittedDate;
  let receiveddateHtml;
  if (receivedDate != null)
  receiveddateHtml = (
      <div>{moment(item.submittedDate).format('MM/DD/YYYY HH:mm:ss')}</div>
    );
  else receiveddateHtml = <div>-</div>;

  const statusType = item.status;
  let statusHtml = '';
  if (statusType.toLowerCase() === 'needs action')
    statusHtml = (
      <div className="font-red to-uppercase">
        {statusType}
        <span className="notification">#</span>
      </div>
    );
  else if (statusType.toLowerCase() === 'submitted')
    statusHtml = (
      <div className="data-table-cell to-uppercase">
        <div>{statusType}</div>
        {item.submittedDate &&
        item.submittedDate !== '' &&
        item.submittedDate !== null ? (
          <div>{moment(item.submittedDate).format('MM/DD/YYYY')}</div>
        ) : (
          ''
        )}
      </div>
    );
  else
    statusHtml = (
      <div className="data-table-cell to-uppercase">{statusType}</div>
    );
  const follow = (follow, item, setActionsDrop) => {
    const data = {
      userId: sessionStorage.getItem('loggedUser'),
      subscribed: follow,
      documentId: item.id,
      emailId: JSON.parse(sessionStorage.getItem('userData')).emailId,
      name:
        JSON.parse(sessionStorage.getItem('userData')).firstName +
        ' ' +
        JSON.parse(sessionStorage.getItem('userData')).lastName,
    };
    if (item.id) {
      const listUrl = `${endpoints.apigee}/document-management/cdc/requirements/v1/requirements-subscribe`;
      callApi
        .postApiLoggedUser(listUrl, data)
        .then((response) => {
          window.location.reload();

          setActionsDrop(0);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const followUp = (id, following) => {
    const maxCount = sessionStorage.getItem('maxCount')
      ? JSON.parse(sessionStorage.getItem('maxCount'))
      : 100;
    const followingCount = sessionStorage.getItem('followingCount')
      ? JSON.parse(sessionStorage.getItem('followingCount'))
      : 0;
    if (followingCount < maxCount) {
      if (following === false && item.id === id) {
        follow(true, item, setActionsDrop);
      } else if (following === true && item.id === id) {
        follow(false, item, setActionsDrop);
      }
    } else if (
      following === false &&
      confirm(
        `You are already following ${maxCount} documents. Are you sure you want to follow?`
      )
    ) {
      if (following === false && item.id === id) {
        follow(true, item, setActionsDrop);
      }
    } else {
      if (following === true && item.id === id) {
        follow(false, item, setActionsDrop);
      }
    }
  };
  const docId = item.id;
  if (statusType != 'Not Submitted' || filterStatus == 'Status') {
    return {
      first: dateHtml,
     
      second: (
        <div>
          <div className="span-bold">{parentCompanyHtml}</div>
          <div className="data-table-subcell">{legalEntityHtml}</div>
        </div>
      ),
      third: (
        <div>
          <div className="span-bold">{dealNametext}</div>
          <div className="data-table-subcell">{dealNumberText}</div>
        </div>
      ),
      fourth: (
        <div className="data-table-cell">
          <div className="span-bold">{docCategory}</div>
          <div className="data-table-subcell">{docRequirement}</div>
          {item.portalSubmitted ? (
            <div className="expand-column-portal">
              <div className="portal-indicator">
                <img src={portalIndicator} />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      ),
      fifth: asofdateHtml,
      sixth: receiveddateHtml,
      seventh: statusHtml,
      eighth: (
        <div>
          <div className="expand-column" data-testid="chck-box">
            <Checkbox
              onChange={() => followUp(item.id, item.following)}
              checked={item.following}
              disabled={disabledID}
              ariaLabelledBy="mark-final"
              tooltip={
                disabledID
                  ? 'User must have assigned portfolio to follow'
                  : 'Click here to Follow'
              }
            />
          </div>
          {/* ) : (
            <div></div>
          )} */}
        </div>
      ),
      ninth: (
        <div className="expand-column">
          <a href={`/ClosingBinders/Portfolio/Requirements?id=${docId}`} title="View details page">
            <div className="expand-column-off">
              <img src={actionlogo}></img>
            </div>
          </a>
        </div>
      ),
    };
  }
  return {
    first: dateHtml,
  
    second: (
      <div>
        <div className="span-bold">{parentCompanyHtml}</div>
        <div className="data-table-subcell">{legalEntityHtml}</div>
      </div>
    ),
    third: (
      <div>
        <div className="span-bold">{dealNametext}</div>
        <div className="data-table-subcell">{dealNumberText}</div>
      </div>
    ),
    fourth: (
      <div className="data-table-cell">
        <div className="span-bold">{docCategory}</div>
        <div className="data-table-subcell">{docRequirement}</div>
        {item.portalSubmitted ? (
          <div className="expand-column-portal">
            <div className="portal-indicator">
              <img src={portalIndicator} />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    ),
    fifth: asofdateHtml,
    sixth: statusHtml,

    seventh: (
      <div>
        {/* <div className="fileStatus title" id="mark-final">
            Follow
          </div>
          <div className="fileStatus title"></div> */}
        {/* {item.employee ? ( */}

        <div className="expand-column" data-testid="chck-box">
          <Checkbox
            onChange={() => followUp(item.id, item.following)}
            checked={item.following}
            disabled={disabledID}
            ariaLabelledBy="mark-final"
            tooltip={
              disabledID
                ? 'User must have assigned portfolio to follow'
                : 'Click here to Follow'
            }
          />
        </div>
        {/* ) : (
            <div></div>
          )} */}
      </div>
    ),

    eighth: (
      <div className="expand-column">
        <a href={`/ClosingBinders/Portfolio/Requirements?id=${docId}`} title="View details page">
          <div className="expand-column-off">
            <img src={actionlogo}></img>
          </div>
        </a>
      </div>
    ),
  };
};

export const searchDrop = (e, setSearchValue) => {
  setSearchValue(e.value);
};

export const downloadDataCheck = (reportResultsFull) => {
  let count = 0;
  reportResultsFull.forEach((item) => {
    const dueDate = item.dueDate.split('T');
    const docdueDate = moment(dueDate, 'YYYYMMDD').format('YYYYMMDD');
    const today = moment(new Date(), 'YYYYMMDD').format('YYYYMMDD');
    if (docdueDate != null) {
      const days = moment(docdueDate, 'YYYYMMDD').diff(
        moment(today, 'YYYYMMDD'),
        'days'
      );
      if (days <= -30 && item.status === 'Not Submitted') {
        count += 1;
      }
    }
  });
  if (count > 0) {
    return true;
  }
  return false;
};

export const ReportingModal = (
  whichReport,
  selectedReportDataSet,
  reportDataSet,
  reportingModal,
  setReportingModal,
  hideReportingModal,
  fileType,
  setFileType,
  hideExcel,
  setHideExcel
) => {
  const [excelData, setExcelData] = useState([]);

  const fileTypes = [{ label: 'Microsoft Excel (xlsx)', value: 'xlsx' }];

  let title = '';
  let filename = '';
  if (whichReport === 'pastdue') {
    title = 'Download all past due reporting (30 days or more) report';
    filename = 'Past_Due_Report';
  } else if (whichReport === 'portfolio') {
    title = 'My portfolio report.';
    filename = 'My_Portfolio_Report';
  }
  const addAriaLabel = () => {
    const searchElement = document.getElementsByClassName('p-dropdown-trigger');
    for (let i = 0; i < searchElement.length; i += 1) {
      searchElement[i].setAttribute('aria-label', 'Click to show more');
    }
  };

  const setDownloadFile = (e) => {
    let tempdata = [];
    const now = moment();
    if (whichReport === 'pastdue') {
      tempdata = [];
      reportDataSet.forEach((item) => {
        const dueDate = item.dueDate.split('T');
        const docdueDate = moment(dueDate, 'YYYYMMDD').format('YYYYMMDD');
        const today = moment(new Date(), 'YYYYMMDD').format('YYYYMMDD');
        if (docdueDate != null) {
          const days = moment(docdueDate, 'YYYYMMDD').diff(
            moment(today, 'YYYYMMDD'),
            'days'
          );
          if (days <= -30 && item.status === 'Not Submitted') {
            tempdata.push({
              REPORT_DATE_OF_CREATION: now.format('MMM DD, YYYY'),
              DUE_DATE: moment(dueDate[0]).format('MMM DD, YYYY'),
              AS_OF_DATE: moment(item.asOfDate).format('MMM DD, YYYY'),
              DEAL_NAME: item.metadata.dealName,
              DEAL_NUMBER: item.metadata.dealId,
              PARENT_COMPANY: item.metadata.parentCompanyName,
              SUBMITTED_DATE: item.submittedDate,
              LEGAL_ENTITY: item.metadata.reportingEntity,
              DOCUMENT_TYPE: item.category,
              'DOCUMENT_SUBTYPE(CATEGORY)': item.requirementName,
              STATUS: item.status,
              ...(sessionStorage.getItem('userrole') === 'employee'
                ? { FUND_NAME: item.metadata.syndicationFundName }
                : ''),
            });
          }
        }
      });
    } else if (whichReport === 'portfolio') {
      tempdata = [];
      selectedReportDataSet.forEach((item) => {
        tempdata.push({
          REPORT_DATE_OF_CREATION: now.format('MMM DD, YYYY'),
          DUE_DATE: moment(item.dueDate).format('MMM DD, YYYY'),
          AS_OF_DATE: moment(item.asOfDate).format('MMM DD, YYYY'),
          DEAL_NAME: item.metadata.dealName,
          DEAL_NUMBER: item.metadata.dealId,
          PARENT_COMPANY: item.metadata.parentCompanyName,
          SUBMITTED_DATE: item.submittedDate,
          LEGAL_ENTITY: item.metadata.reportingEntity,
          DOCUMENT_TYPE: item.category,
          'DOCUMENT_SUBTYPE(CATEGORY)': item.requirementName,
          STATUS: item.status,
          ...(sessionStorage.getItem('userrole') === 'employee'
            ? { FUND_NAME: item.metadata.syndicationFundName }
            : ''),
        });
      });
    }
    setExcelData(tempdata);
    setFileType(e.target.value);
    if (e.target.value === 'pdf') {
      setHideExcel(false);
    } else {
      setHideExcel(true);
    }
  };

  useEffect(() => {
    addAriaLabel();
  });

  return (
    <USBModal className="download-modal" isOpen={reportingModal}>
      <USBModalCloseIcon
        handleModalClose={() => hideReportingModal(setReportingModal)}
        className="errormodalclosebtn"
      />
      <USBModalHeader modalTitle={title} />
      <USBModalBody>
        <div className="modal-description">
          To download the report, first choose your preferred format from
          the&nbsp;
          <strong>File type</strong>
          &nbsp;menu
        </div>
        <div>
          <div className="download-file-type-dropdown">
            <Dropdown
              value={fileType}
              options={fileTypes}
              onChange={(e) => setDownloadFile(e)}
              placeholder="File Type"
              className="file-type"
              ariaLabel="file type"
            />
          </div>
        </div>
      </USBModalBody>
      <USBModalFooter>
        <div className="reportDownloadModal">
          <span className="report-cancel-btn">
            <USBButton
              handleClick={() => hideReportingModal(setReportingModal)}
            >
              Cancel
            </USBButton>
          </span>
          {hideExcel && (
            <USBButton
              variant="primary"
              handleClick={() =>
                ExportToExcel(
                  Object.values(excelData),
                  filename,
                  setReportingModal,
                  setHideExcel
                )
              }
            >
              Download
            </USBButton>
          )}
        </div>
      </USBModalFooter>
    </USBModal>
  );
};

const ClosingBinderPortfolio = () => {
  const [employeeFlag, setEmployeeFlag] = useState(false);
  // const [portalAdmin, setPortalAdmin] = useState(false)
  const [tempSessions, setTempSessions] = useState(false);
  const [reportingModal, setReportingModal] = useState(false);
  const [hideExcel, setHideExcel] = useState(false);
  const [fileType, setFileType] = useState('');
  const [whichReport, setWhichReport] = useState('');
  const [rangeArray, setRangeArray] = useState(['25', '50', '75', '100']);
  const [checked, setChecked] = useState(false);
  const [refreshUI,setRefreshUI] = useState(false)
  const history = useHistory();
  const {
    reportResults,
    setReportResults,
    reportResultsFull,
    reportingEntity,
    dealName,
    dealId,
    requirementName,
    statusDrop,
    parentCompany,
    submittedDate,
    asOfDate,
    loading,
    setLoading,
    dashboardData,
    setDashboardData, // statusValue, setStatusValue,
    syndicationFund,
    sortBy,
    searchData,
    totalPages,
    setTotalPages,
    totalResults,
    setTotalResults,
    activeFilters,
    setActiveFilters,
    loadFilterS,
    getCBAPIResponse,
    emptyFilters,
    sortByValue,
    setSortByValue,
    searchValue,
    setSearchValue,
    clearFilter,
    clearFilterForReports,
    showSyndication,
    setReportingEntity,
    setActionsDrop,
    followCheck,
    parentCompanyCpy,
    reportingEntityCpy,
    dealNameCpy,
    dealIdCpy,
    syndicationFundCpy,
  } = useContext(DashboardContext);
  const { assignedUserName, servicingUserRole, setCurrentMenu } =
    useContext(DocContext);
  const [filterReportingEntity, setFilterEntity] = useState([]);
  const [filterDealName, setfilterDealName] = useState([]);
  const [filterDealId,setfilterDealId] = useState([]);
  const [filterParentCompany, setfilterParentCompany] = useState([]);
  const [contentOpen, setContentOpen] = useState(false);
  const [contentOpenDeal, setcontentOpenDeal] = useState(false);
  const [contentOpenDealId, setcontentOpenDealId] = useState(false);
  const [contentOpenParent, setcontentOpenParent] = useState(false);
  const [InputValue, setInputValue] = useState('');
  const [dealInputValue, setDealInputValue] = useState('');
  const [dealIdInputValue, setDealIdInputValue] = useState('');
  const [parentInputValue, setparentInputValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const closeOpenMenus = (event)=>{
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      if (event.srcElement.className !== 'down-image' && event.srcElement.className !== 'dropdown-each'
      ) {
        setContentOpen(false);
        setcontentOpenDeal(false);
        setcontentOpenParent(false)
      }
    }
}

  const tempUserRole = useUserRole();
  const pccpy = JSON.stringify(parentCompanyCpy);
  const forwardClickHandler = () => {
    setCurrentPage(currentPage + 1);
  };
  const backwardClickHandler = () => {
    setCurrentPage(currentPage === 1 ? 1 : currentPage - 1);
  };
  const controlOpts = {
    controlled: true,
    handleForwardClick: forwardClickHandler,
    handleBackwardClick: backwardClickHandler,
  };

  useEffect(() => {
    document.addEventListener('click',closeOpenMenus,true);
    if (getQS('a') === 'c') {
      setTempSessions(true);
    }
    if (!sessionStorage.getItem('tok')) {
      history.push('/');
    } else {
      setCurrentMenu('portfolio');
      setUserRole();
      // console.log(JSON.parse(sessionStorage.getItem('remote_user')));
      // clearFilterForReports(sessionStorage.getItem('assignedUser'));
      if (JSON.parse(sessionStorage.getItem('remote_user'))) {
        getCBAPIResponse(
          JSON.parse(sessionStorage.getItem('remote_user')).userID,
          '',
          true
        );
      } else if (sessionStorage.getItem('view_as')) {
        getCBAPIResponse(
          `${JSON.parse(sessionStorage.getItem('view_as')).bankerId}@cdcemp`,
          '',
          true
        );
      } else {
        getCBAPIResponse(sessionStorage.getItem('assignedUser'), ''); // , false, true
      }

      if (
        sessionStorage.getItem('userrole') === 'employee' ||
        servicingUserRole === 'employee'
      ) {
        setEmployeeFlag(true);
      }
    }
    // if (sessionStorage.getItem('userrole') === 'portaladmin') {
    //   setPortalAdmin(true)
    // }
  }, []);
  

  // CHANGED - TO CONVERT TO INTERNAL USER
  // Temp method to set session values in local dev
  const setSessionValues = () => {
    sessionStorage.setItem('role', 'APP_8083_CDCWeb_Super_Admins');
    sessionStorage.setItem('assignedUser', 'c071993@cdcemp');
    sessionStorage.setItem('assignedUserData', '{"firstName":"You","lastName":""}');
    sessionStorage.setItem('type', 'employee_signon');
    sessionStorage.setItem('userrole', 'employee');
    sessionStorage.setItem('loggedUser', 'c071993@cdcemp');
    sessionStorage.setItem('userData','{"userId":"c071993","emailId":null,"firstName":"User","lastName":null,"middleName":null,"profileId":null,"emailOptFlag":true}');
    sessionStorage.setItem('employeeNameData', '[{"firstName":"You (default)","lastName":"","fullName":"You","userId":"c071993@cdcemp"},{"firstName":"Theo","lastName":"Boykin","fullName":"Boykin, Theo","userId":"txboyki@cdcemp"},{"firstName":"Scott","lastName":"Iserman","fullName":"Iserman, Scott","userId":"sciserm@cdcemp"},{"firstName":"Kristin","lastName":"Zemansky","fullName":"Zemansky, Kristin","userId":"kmzeman@cdcemp"}]');
    sessionStorage.setItem('servrole', 'siteadmin');
    window.location.reload();
  };
  const items = dashboardData;
  const showReportingModal = (setUploadedDocModalOpen, reportName) => {
    setWhichReport(reportName);
    setFileType('');
    setUploadedDocModalOpen(true);
  };
  const hideReportingModal = (setUploadedDocModalOpen, reportName) => {
    setWhichReport(reportName);
    setFileType('');
    setUploadedDocModalOpen(false);
    setHideExcel(false);
  };
  const paginationtableData = (reportResults, filterStatus) => {
    let paginationData = [];

    if (reportResults.length != 0) {
      paginationData = [];
      reportResults.map((item) => {
        paginationData.push(
          tblContentTemplate(item, filterStatus, setActionsDrop)
        );
      });
    }

    return paginationData;
  };

  const defaultContent = {
    dateInput: {
      calendarButtonAriaLabel: 'Choose date',
      helperText: 'mm/dd/yyyy',
      helperTextOptional: 'Optional',
      labelText: 'Received date',
      labelText2: 'As of Date',
      errorMessages: {
        default: 'Please enter a valid date.',
        202: 'Date should be in the past.',
        203: 'Date should be in the future.',
        221: 'Enter no more than 10 characters.',
        213: 'Enter numbers only.',
        224: '(Optional field)',
        228: "That date isn't available. Please try again.",
      },
    },
  };

  const defaultSettingsDateInput = {
    initValue: undefined,
    isDateOfBirth: true,
    // minimumAge: 0,
    // maximumAge: 10,
    // maxlength: 10,
    showRenderCount: false,
    inputName: 'date-picker_single-date--default',
    isDisabled: false,
    isOptional: false,
    isReadOnly: false,
    dataTestId: null,
  };

  const defaultSettingsCalendar = {
    areBankHolidaysDisabled: defaultSettingsDateInput.isDateOfBirth
      ? false
      : true,
    areWeekendsDisabled: defaultSettingsDateInput.isDateOfBirth ? false : true,
    occurrence: defaultSettingsDateInput.isDateOfBirth ? 'past' : 'future',
    numberOfYearsToShow: '10',
  };

  const defaultContent1 = {
    dateInput: {
      calendarButtonAriaLabel: 'Choose date',
      helperText: 'mm/dd/yyyy',
      helperTextOptional: 'Optional',
      labelText: 'As of Date',
      errorMessages: {
        default: 'Please enter a valid date.',
        202: 'Date should be in the past.',
        203: 'Date should be in the future.',
        221: 'Enter no more than 10 characters.',
        213: 'Enter numbers only.',
        224: '(Optional field)',
        228: "That date isn't available. Please try again.",
      },
    },
  };

  const defaultSettingsDateInput1 = {
    initValue: undefined,
    isDateOfBirth: true,
    // minimumAge: 0,
    // maximumAge: 10,
    // maxlength: 10,
    showRenderCount: false,
    inputName: 'date-picker_single-date--default',
    isDisabled: false,
    isOptional: false,
    isReadOnly: false,
    dataTestId: null,
  };

  const defaultSettingsCalendar1 = {
    areBankHolidaysDisabled: defaultSettingsDateInput1.isDateOfBirth
      ? false
      : true,
    areWeekendsDisabled: defaultSettingsDateInput1.isDateOfBirth ? false : true,
    occurrence: defaultSettingsDateInput1.isDateOfBirth ? 'past' : 'future',
    numberOfYearsToShow: '10',
  };

  const updateRangeArray = (pageCount) => {
    const rangeArrayTemp = rangeArray.filter((e) => e !== pageCount);
    rangeArrayTemp.sort(function (a, b) {
      return a - b;
    });
    rangeArrayTemp.unshift(pageCount);
    setRangeArray(rangeArrayTemp);
  };

  const loadByEntityName = (
    item,
    columnName,
    activeFilters,
    setActiveFilters,
    reportResultsFull,
    setReportResults,
    setDashboardData,
    dashboardData,
    sortByValue,
    setTotalPages,
    setTotalResults,
    searchValue,
    loadFilterS,
    emptyFilters,
    getCBAPIResponse
  ) => {
    // console.log(item);
    // sessionStorage.setItem('assignedUser', item.userId);
    // window.location.reload();
    // setEmployeeNameId(item.fullName)
    // if (currentMenu === 'portfolio') {
    // getCBAPIResponse(item.userId, 'Submitted')
    //   clearFilter(item.userId);
    // }
    // getNotificationCount(item.userId);
    // getAssignedserName(item.userId);
    setRefreshUI(true)
    if (columnName === 'reportingEntity') {
      setInputValue(item);
      setDealInputValue('');
      setDealIdInputValue('')
    }
    if (columnName === 'dealName') {
      setDealInputValue(item);
    }
    if (columnName === 'dealId') {
      setDealIdInputValue(item);
    }
    if (columnName === 'parentCompany') {
      setparentInputValue(item);
      setInputValue('');
      setDealInputValue('');
      setDealIdInputValue('')
    }
    filterData(
      item,
      columnName,
      activeFilters,
      setActiveFilters,
      reportResultsFull,
      setReportResults,
      setDashboardData,
      dashboardData,
      sortByValue,
      setTotalPages,
      setTotalResults,
      searchValue,
      loadFilterS,
      emptyFilters,
      getCBAPIResponse,
      setRefreshUI
    );
    setContentOpen(false);
    setcontentOpenDeal(false);
    setcontentOpenParent(false);
    setcontentOpenDealId(false)
    // setEmployeeName(employeeNameFull);
    // if (currentMenu === 'notifications') {
    //   listNotifications(item.userId);
    // }
  };
  const compNameKepress = (event, item) => {
    if (event.keyCode === 27) {
      setContentOpen(false);
      setcontentOpenParent(false);
      setcontentOpenDeal(false);
    }
  };
  const searchDropFilter = (value) => {
    setcontentOpenParent(false);
    setcontentOpenDeal(false);
    let temp = [];
    setFilterEntity([]);
    setInputValue(value);
    if (value !== '' || value.length !== 0) {
      // console.log(reportingEntity,"checkkkk");
      reportingEntity.forEach((item) => {
        if (
          (item.content !== null && item.content !== '') ||
          (item.value !== null && item.value !== '')
        ) {
          const itemValue = '' + item.value;
          const itemContent = '' + item.content;
          const inputValue = '' + value;
          if (
            itemValue.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
            itemContent.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          ) {
            temp.push(item);
            setContentOpen(true);
          }
        } else {
          setContentOpen(false);
        }
      });
      setFilterEntity(temp);
    } else {
      temp = reportingEntity;
      setFilterEntity(temp);
      loadByEntityName(
        '',
        'reportingEntity',
        activeFilters,
        setActiveFilters,
        reportResultsFull,
        setReportResults,
        setDashboardData,
        dashboardData,
        sortByValue,
        setTotalPages,
        setTotalResults,
        searchValue,
        loadFilterS,
        emptyFilters,
        getCBAPIResponse
      );
    }
    setContentOpen(true);
  };
  const toggleContent = () => {
    // console.log(reportingEntity,reportingEntityCpy,"checkkk");
    setFilterEntity(reportingEntityCpy);
    if (contentOpen) {
      setContentOpen(false);
    } else {
      setContentOpen(true);
      setcontentOpenDeal(false);
      setcontentOpenParent(false);
      setcontentOpenDealId(false)
      // setReportingEntity(reportingEntity);
    }
    // setContentOpen(true);
  };
  const toggleContentDealName = () => {
    setfilterDealName(dealNameCpy);
    if (contentOpenDeal) {
      setcontentOpenDeal(false);
    } else {
      setcontentOpenDeal(true);
      setContentOpen(false);
      setcontentOpenDealId(false)
      setcontentOpenParent(false);
    }
  };

  const toggleContentDealId = () => {
    setfilterDealId(dealIdCpy);
    if (contentOpenDealId) {
      setcontentOpenDealId(false);
    } else {
      setcontentOpenDealId(true);
      setcontentOpenDeal(false)
      setContentOpen(false);
      setcontentOpenParent(false);
    }
  };

  const searchDropFilterDeal = (value) => {
    setContentOpen(false);
    setcontentOpenParent(false);
    setcontentOpenDealId(false)
    let temp = [];
    setfilterDealName([]);
    setDealInputValue(value);
    if (value !== '' || value.length !== 0) {
      dealName.forEach((item) => {
        if (
          (item.content !== null && item.content !== '') ||
          (item.value !== null && item.value !== '')
        ) {
          const itemValue = '' + item.value;
          const itemContent = '' + item.content;
          const inputValue = '' + value;
          if (
            itemValue.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
            itemContent.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          ) {
            temp.push(item);
            setcontentOpenDeal(true);
          }
        } else {
          setcontentOpenDeal(false);
        }
      });
      setfilterDealName(temp);
    } else {
      temp = dealName;
      setfilterDealName(temp);
      loadByEntityName(
        '',
        'dealName',
        activeFilters,
        setActiveFilters,
        reportResultsFull,
        setReportResults,
        setDashboardData,
        dashboardData,
        sortByValue,
        setTotalPages,
        setTotalResults,
        searchValue,
        loadFilterS,
        emptyFilters,
        getCBAPIResponse
      );
    }
    setcontentOpenDeal(true);
  };
  const searchDropFilterDealId = (value) => {
    setContentOpen(false);
    setcontentOpenParent(false);
    setcontentOpenDeal(false)
    let temp = [];
    setfilterDealId([]);
    setDealIdInputValue(value);
    if (value !== '' || value.length !== 0) {
      dealId.forEach((item) => {
        if (
          (item.content !== null && item.content !== '') ||
          (item.value !== null && item.value !== '')
        ) {
          const itemValue = '' + item.value;
          const itemContent = '' + item.content;
          const inputValue = '' + value;
          if (
            itemValue.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
            itemContent.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          ) {
            temp.push(item);
            setcontentOpenDealId(true);
          }
        } else {
          setcontentOpenDealId(false);
        }
      });
      setfilterDealId(temp);
    } else {
      temp = dealId;
      setfilterDealId(temp);
      loadByEntityName(
        '',
        'dealId',
        activeFilters,
        setActiveFilters,
        reportResultsFull,
        setReportResults,
        setDashboardData,
        dashboardData,
        sortByValue,
        setTotalPages,
        setTotalResults,
        searchValue,
        loadFilterS,
        emptyFilters,
        getCBAPIResponse
      );
    }

    setcontentOpenDealId(true);
  };
  const toggleContentParentCompany = () => {
    setfilterParentCompany(parentCompanyCpy);
    // console.log(contentOpenParent);
    if (contentOpenParent) {
      setcontentOpenParent(false);
    } else {
      setcontentOpenParent(true);
      setContentOpen(false);
      setcontentOpenDeal(false);
      setcontentOpenDealId(false)
    }

    // setContentOpen(true);
  };
  const searchDropFilterParentCompany = (value) => {
    setContentOpen(false);
    setcontentOpenDeal(false);
    let temp = [];
    setfilterParentCompany([]);
    setparentInputValue(value);
    if (value !== '' || value.length !== 0) {
      parentCompany.forEach((item) => {
        if (
          (item.content !== null && item.content !== '') ||
          (item.value !== null && item.value !== '')
        ) {
          const itemValue = '' + item.value;
          const itemContent = '' + item.content;
          const inputValue = '' + value;
          if (
            itemValue.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
            itemContent.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          ) {
            temp.push(item);
            setcontentOpenParent(true);
          }
        } else {
          setcontentOpenParent(false);
        }
      });
      setfilterParentCompany(temp);
    } else {
      temp = parentCompany;
      setfilterParentCompany(temp);
      loadByEntityName(
        '',
        'parentCompany',
        activeFilters,
        setActiveFilters,
        reportResultsFull,
        setReportResults,
        setDashboardData,
        dashboardData,
        sortByValue,
        setTotalPages,
        setTotalResults,
        searchValue,
        loadFilterS,
        emptyFilters,
        getCBAPIResponse
      );
    }

    setcontentOpenParent(true);
  };
  return (
    <>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={16}>
          {sessionStorage.getItem('remote_user') ? (
            <div className="warning-panel-remote">
              You are impersonating the user:{' '}
              {JSON.parse(sessionStorage.getItem('remote_user')).firstName}{' '}
              {JSON.parse(sessionStorage.getItem('remote_user')).lastName}. To
              exit, click the 'Exit User' link above.
            </div>
          ) : (
            ''
          )}
        </USBColumn>

        <USBColumn largeSpan={8}>
          <USBBreadcrumb
            id="cbPortfolio"
            text="CB Dashboard"
            breadcrumbs={[
              { id: 1, text: 'CB Portfolio', href: '/ClosingBinders/Portfolio' },
          ]}
          />
        </USBColumn>
        <USBColumn largeSpan={8}>
          {ReportingModal(
            whichReport,
            reportResults,
            reportResultsFull,
            reportingModal,
            setReportingModal,
            hideReportingModal,
            fileType,
            setFileType,
            hideExcel,
            setHideExcel
          )}
          <div className="search-frame">
            <div className="search-section">
              <Dropdown
              className='global-search-dropdown'
                value={searchValue}
                options={searchData}
                onChange={(e) => searchDrop(e, setSearchValue)}
                placeholder="Search by"
                ariaLabel="search by"
              />
              <input
                style={{ backgroundImage: `url(${searchIcon})` }}
                className="input-search-global"
                aria-label="search input"
                type="search"
                placeholder='Search'
                value={items.search}
                onChange={(e) =>
                  handleSearchChange(
                    e.target.value,
                    reportResultsFull,
                    searchValue,
                    setReportResults,
                    setDashboardData,
                    dashboardData,
                    setTotalPages,
                    setTotalResults,
                    activeFilters,
                    sortByValue
                  )
                }
              />
            </div>
          </div>
        </USBColumn>
      </USBGrid>
      <USBGrid addClasses="content-frame content-bg">
        <USBColumn largeSpan={16}>
          {loading ? <Loader /> : null}
          {refreshUI ? <Loader /> : null}
          {/* {/* Temp method to set session values in local dev */}
          {tempSessions === true ? (
            <button
              type="button"
              onClick={setSessionValues}
              style={{
                padding: '4px',
                color: '#ffffff',
                backgroundColor: '#0a41c5',
                border: '1px solid #0a41c5',
                fontFamily: "U.S. Bank Circular",
                borderRadius: '4px',
                marginLeft: '4px',
                position: 'fixed',
                zIndex: '100001',
              }}
            >
              Set Session
            </button>
          ) : (
            ''
          )}
          <h1 className="heading-h1">
            Closing Binders Portfolio
          </h1>
        
          <div className="filter-by">Filter by:</div>
          <div className="sorting-inputs">
            
            {(employeeFlag ||
              servicingUserRole === 'vendorsiteadmin' ||
              servicingUserRole === 'vendorcontributor' ||
              servicingUserRole === 'unlistedvendor') &&
            !JSON.parse(sessionStorage.getItem('remote_user')) ? (
              <div className="autofilterDropdowns">
                <p className="heading">Parent Company</p>
                <div className="inputWithButton">
                  <button
                    type="button"
                    onClick={() => toggleContentParentCompany()}
                    className="dropbtn"
                  >
                    {' '}
                    &nbsp;{' '}
                    <img src={down} alt="down" className="down-image"></img>
                  </button>

                  <input
                    type="text"
                    className="dropdownelement"
                    placeholder="Search by Parent Company"
                    id="parentCompanyInput"
                    onChange={(e) =>
                      searchDropFilterParentCompany(e.target.value)
                    }
                    value={parentInputValue}
                  />
                </div>
                {contentOpenParent ? (
                  <>
                    <div className="Autocomplete">
                      {!loading &&
                        filterParentCompany &&
                        filterParentCompany.length > 0 &&
                        filterParentCompany.map((item, index) => (
                          <div
                            key={index + 'p'}
                            className="dropdown-each"
                            role="button"
                            tabIndex="0"
                            onKeyDown={(event) =>
                              compNameKepress(event, item.value)
                            }
                            onClick={() =>
                              loadByEntityName(
                                item.value,
                                'parentCompany',
                                activeFilters,
                                setActiveFilters,
                                reportResultsFull,
                                setReportResults,
                                setDashboardData,
                                dashboardData,
                                sortByValue,
                                setTotalPages,
                                setTotalResults,
                                searchValue,
                                loadFilterS,
                                emptyFilters,
                                getCBAPIResponse
                              )
                            }
                          >
                            {/* {item.content} */}
                            {item.value}
                          </div>
                        ))}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
        

          <div className="autofilterDropdowns">
              <p className="heading">Business Unit</p>
              <div className="inputWithButton">
                <button
                  type="button"
                  onClick={() => toggleContent()}
                  className="dropbtn"
                >
                  {' '}
                  &nbsp;{' '}
                  <img src={down} alt="down" className="down-image"></img>
                </button>

                <input
                  type="text"
                  className="dropdownelement"
                  placeholder="Search by Business Unit"
                  id="legalEntityInput"
                  onChange={(e) => searchDropFilter(e.target.value)}
                  value={InputValue}
                />
              </div>
              {contentOpen ? (
                <>
                  <div className="Autocomplete">
                    {!refreshUI && !loading && 
                      filterReportingEntity &&
                      filterReportingEntity.length > 0 &&
                      filterReportingEntity.map((item, index) => (
                        <div
                          key={index + 'l'}
                          className="dropdown-each"
                          role="button"
                          tabIndex="0"
                          onKeyDown={(event) =>
                            compNameKepress(event, item.value)
                          }
                          onClick={() =>
                            loadByEntityName(
                              item.value,
                              'reportingEntity',
                              activeFilters,
                              setActiveFilters,
                              reportResultsFull,
                              setReportResults,
                              setDashboardData,
                              dashboardData,
                              sortByValue,
                              setTotalPages,
                              setTotalResults,
                              searchValue,
                              loadFilterS,
                              emptyFilters,
                              getCBAPIResponse
                            )
                          }
                        >
                          {/* {item.content} */}
                          {item.value}
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>

            <div className="autofilterDropdowns">
              <p className="heading">Deal Name</p>
              <div className="inputWithButton">
                <button
                  type="button"
                  onClick={() => toggleContentDealName()}
                  className="dropbtn"
                >
                  {' '}
                  &nbsp;{' '}
                  <img src={down} alt="down" className="down-image"></img>
                </button>

                <input
                  type="text"
                  className="dropdownelement"
                  placeholder="Search by Deal ID/Name"
                  id="dealNameInput"
                  onChange={(e) => searchDropFilterDeal(e.target.value)}
                  value={dealInputValue}
                />
              </div>
              {contentOpenDeal ? (
                <>
                  <div className="Autocomplete">
                    {!refreshUI && !loading && 
                      filterDealName &&
                      filterDealName.length > 0 &&
                      filterDealName.map((item, index) => (
                        <div
                          key={index + 'l'}
                          className="dropdown-each"
                          role="button"
                          tabIndex="0"
                          onKeyDown={(event) =>
                            compNameKepress(event, item.value)
                          }
                          onClick={() =>
                            loadByEntityName(
                              item.value,
                              'dealName',
                              activeFilters,
                              setActiveFilters,
                              reportResultsFull,
                              setReportResults,
                              setDashboardData,
                              dashboardData,
                              sortByValue,
                              setTotalPages,
                              setTotalResults,
                              searchValue,
                              loadFilterS,
                              emptyFilters,
                              getCBAPIResponse
                            )
                          }
                        >
                          {/* {item.content} */}
                          {item.value}
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
            <div className="autofilterDropdowns">
              <p className="heading">Deal Id</p>
              <div className="inputWithButton">
                <button
                  type="button"
                  onClick={() => toggleContentDealId()}
                  className="dropbtn"
                >
                  {' '}
                  &nbsp;{' '}
                  <img src={down} alt="down" className="down-image"></img>
                </button>

                <input
                  type="text"
                  className="dropdownelement"
                  placeholder="Search by Deal ID"
                  id="dealIdInput"
                  onChange={(e) => searchDropFilterDealId(e.target.value)}
                  value={dealIdInputValue}
                />
              </div>
              {contentOpenDealId ? (
                <>
                  <div className="Autocomplete">
                    {!refreshUI && !loading && 
                      filterDealId &&
                      filterDealId.length > 0 &&
                      filterDealId.map((item, index) => (
                        <div
                          key={index + 'l'}
                          className="dropdown-each"
                          role="button"
                          tabIndex="0"
                          onKeyDown={(event) =>
                            compNameKepress(event, item.value)
                          }
                          onClick={() =>
                            loadByEntityName(
                              item.value,
                              'dealId',
                              activeFilters,
                              setActiveFilters,
                              reportResultsFull,
                              setReportResults,
                              setDashboardData,
                              dashboardData,
                              sortByValue,
                              setTotalPages,
                              setTotalResults,
                              searchValue,
                              loadFilterS,
                              emptyFilters,
                              getCBAPIResponse
                            )
                          }
                        >
                          {/* {item.content} */}
                          {item.value}
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
         
              <div className="filterDropdowns">
              { !loading && <USBSelect
                id="documenttype"
                name="documenttype"
                initValue={items.requirementNameValue}
                labelText="Document type"
                optionsArray={requirementName}
                handleChange={(e) =>
                  filterData(
                    e,
                    'requirementName',
                    activeFilters,
                    setActiveFilters,
                    reportResultsFull,
                    setReportResults,
                    setDashboardData,
                    dashboardData,
                    sortByValue,
                    setTotalPages,
                    setTotalResults,
                    searchValue,
                    loadFilterS,
                    emptyFilters,
                    getCBAPIResponse,
                    setRefreshUI
                  )
                }
                // ariaLabel="document type"
              /> }
            </div>

            <div className="filterDropdowns">
              <USBSelect
                id="status"
                name="status"
                initValue={items.statusValue}
                labelText="Status"
                optionsArray={statusDrop}
                handleChange={(e) =>
                  filterData(
                    e,
                    'status',
                    activeFilters,
                    setActiveFilters,
                    reportResultsFull,
                    setReportResults,
                    setDashboardData,
                    dashboardData,
                    sortByValue,
                    setTotalPages,
                    setTotalResults,
                    searchValue,
                    loadFilterS,
                    emptyFilters,
                    getCBAPIResponse,
                    setRefreshUI
                  )
                }
                // ariaLabel="status"
              />
            </div>

            
            {employeeFlag && !sessionStorage.getItem('remote_user') ? (
              <div className="filterDropdowns">
                <USBSelect
                  id="follow"
                  name="follow"
                  initValue={items.followValue}
                  labelText="Following"
                  optionsArray={followCheck}
                  handleChange={(e) =>
                    filterData(
                      e,
                      'followCheck',
                      activeFilters,
                      setActiveFilters,
                      reportResultsFull,
                      setReportResults,
                      setDashboardData,
                      dashboardData,
                      sortByValue,
                      setTotalPages,
                      setTotalResults,
                      searchValue,
                      loadFilterS,
                      emptyFilters,
                      getCBAPIResponse,
                      setRefreshUI
                    )
                  }
                  // ariaLabel="status"
                />
              </div>
            ) : (
              ''
            )}
          
            {employeeFlag ||
            servicingUserRole === 'vendorsiteadmin' ||
            servicingUserRole === 'vendorcontributor' ||
            servicingUserRole === 'unlistedvendor' ||
            servicingUserRole === 'siteadmin' ||
            servicingUserRole === 'contributor' ||
            servicingUserRole === 'customer' ? (
              <div className="datefilterDropdowns">
                { !loading && <USBDatePicker
                  id="asofdate"
                  inputName="asofdate"
                  initValue={items.asOfDateValue}
                  labelText2="As of Date"
                  statusUpdateCallback={(e) =>
                    filterData(
                      e,
                      'asOfDate',
                      activeFilters,
                      setActiveFilters,
                      reportResultsFull,
                      setReportResults,
                      setDashboardData,
                      dashboardData,
                      sortByValue,
                      setTotalPages,
                      setTotalResults,
                      searchValue,
                      loadFilterS,
                      emptyFilters,
                      getCBAPIResponse,
                      setRefreshUI
                    )
                  }
                  settings={{
                    dateInput: {
                      ...defaultSettingsDateInput1,
                    },
                    calendar: {
                      id: 'datepicker1',
                      ...defaultCalendarSettings,
                      ...defaultSettingsCalendar1,
                      occurrence: 'both',
                      numberOfYearsToShow: '10',
                      labelText: 'As of Date',
                    },
                  }}
                  content={{
                    ...defaultContent1,
                    labelText: 'Start date',
                    inputName: 'asofdate',
                  }}
                /> }
              </div>
            ) : (
              ''
            )}

            {(employeeFlag ||
              servicingUserRole === 'vendorsiteadmin' ||
              servicingUserRole === 'vendorcontributor' ||
              servicingUserRole === 'unlistedvendor' ||
              servicingUserRole === 'siteadmin' ||
              servicingUserRole === 'contributor' ||
              servicingUserRole === 'customer') &&
            activeFilters.status !== 'Not Submitted' ? (
              <>
                <div className="datefilterDropdowns">
                  { !loading && <USBDatePicker
                    id="submitteddate"
                    inputName="submitteddate"
                    initValue={items.submittedDateValue}
                    labelText="Received Date"
                    statusUpdateCallback={(e) =>
                      filterData(
                        e,
                        'submittedDate',
                        activeFilters,
                        setActiveFilters,
                        reportResultsFull,
                        setReportResults,
                        setDashboardData,
                        dashboardData,
                        sortByValue,
                        setTotalPages,
                        setTotalResults,
                        searchValue,
                        loadFilterS,
                        emptyFilters,
                        getCBAPIResponse,
                        setRefreshUI
                      )
                    }
                    settings={{
                      dateInput: {
                        ...defaultSettingsDateInput,
                      },
                      calendar: {
                        id: 'datepicker2',
                        ...defaultCalendarSettings,
                        ...defaultSettingsCalendar,
                        occurrence: 'past',
                        numberOfYearsToShow: '10',
                        labelText: 'Received Date',
                      },
                    }}
                    content={{
                      ...defaultContent,
                      labelText: 'Start date',
                      inputName: 'submitteddate',
                    }}
                  /> }
                </div>
              </>
            ) : (
              ''
            )}
            </div>
            <div className="clearFilter">
              <USBButton
                id="clearfilter"
                name="clearfilter"
                variant="utility"
                className="clear-filter"
                handleClick={() => {
                  setLoading(true)
                  setInputValue('');
                  setDealInputValue('');
                  setDealIdInputValue('');
                  setparentInputValue('');
                  clearFilter('cb');
                  setTimeout(()=>{
                    setLoading(false)
                  },500)
                  // window.location.href = '/ClosingBinderPortfolio';
                }}
              >
                Clear filters
              </USBButton>
            </div>
            <div className="filterDropdowns">
              <Dropdown
                value={sortByValue}
                options={sortBy}
                placeholder="Sort by"
                onChange={(e) =>
                  sortFilteredData(
                    e,
                    reportResults,
                    setReportResults,
                    setSortByValue,
                    setTotalPages,
                    setTotalResults
                  )
                }
                ariaLabel="sort by"
              />
            </div>
          
          <div className="totalresultsno">
            {totalResults}
            &nbsp; results
          </div>
          {/* {JSON.stringify(reportResults)} */}
          <div className="page-content">
            {activeFilters?.status != 'Not Submitted' ||
            activeFilters?.status == 'Status'
              ? !loading && (
                  <USBTable
                    addClasses="pagination-container"
                    displayVariant="auto"
                    useBorder="true"
                    paginationOptions={rangeArray}
                    handleItemCountChange={(itemCount) =>
                      updateRangeArray(itemCount)
                    }
                    paginationLabels={{
                      previousButton: 'Previous page',
                      nextButton: 'Next page',
                      unit: 'Page',
                      preposition: 'of',
                      rowDropdown: 'Per page',
                    }}
                    paginationPosition="both"
                    columnData={
                      !sessionStorage.getItem('remote_user') && tempUserRole === 'employee'
                        ? [
                            {
                              headerName: 'DUE DATE',
                              fieldFor: 'first',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                           
                            {
                              headerName: 'PARENT COMPANY',
                              fieldFor: 'second',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DEAL NAME / DEAL ID',
                              fieldFor: 'third',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DOCUMENT TYPE',
                              fieldFor: 'fourth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'AS OF DATE',
                              fieldFor: 'fifth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'RECEIVED DATE',
                              fieldFor: 'sixth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'STATUS',
                              fieldFor: 'seventh',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'FOLLOW',
                              fieldFor: 'eighth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'ACTION',
                              fieldFor: 'ninth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                           
                          ]
                        : [
                            {
                              headerName: 'DUE DATE',
                              fieldFor: 'first',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                           
                            {
                              headerName: 'PARENT COMPANY',
                              fieldFor: 'second',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DEAL NAME / DEAL ID',
                              fieldFor: 'third',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DOCUMENT TYPE',
                              fieldFor: 'fourth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'AS OF DATE',
                              fieldFor: 'fifth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'RECEIVED DATE',
                              fieldFor: 'sixth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'STATUS',
                              fieldFor: 'seventh',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'ACTION',
                              fieldFor: 'ninth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                           
                          ]
                    }
                    tableData={
                      reportResults.length != 0
                        ? paginationtableData(
                            reportResults,
                            activeFilters.status
                          )
                        : [
                            {
                              first: '-',
                              second: '-',
                              third: '-',
                              fourth: '-',
                              fifth: '-',
                              sixth: '-',
                              seventh: '',
                              eighth: ' ',
                              ninth: '',
                            },
                          ]
                    }
                  />
                )
              : !loading && (
                  <USBTable
                    addClasses="pagination-container"
                    displayVariant="auto"
                    useBorder="true"
                    paginationOptions={rangeArray}
                    handleItemCountChange={(itemCount) =>
                      updateRangeArray(itemCount)
                    }
                    paginationLabels={{
                      previousButton: 'Previous page',
                      nextButton: 'Next page',
                      unit: 'Page',
                      preposition: 'of',
                      rowDropdown: 'Per page',
                    }}
                    paginationPosition="both"
                    columnData={
                      sessionStorage.getItem('userrole') === 'employee' &&
                      !sessionStorage.getItem('remote_user')
                        ? [
                            {
                              headerName: 'DUE DATE',
                              fieldFor: 'first',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                           
                            {
                              headerName: 'PARENT COMPANY',
                              fieldFor: 'second',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DEAL NAME / DEAL ID',
                              fieldFor: 'third',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DOCUMENT TYPE',
                              fieldFor: 'fourth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'AS OF DATE',
                              fieldFor: 'fifth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'STATUS',
                              fieldFor: 'sixth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'FOLLOW',
                              fieldFor: 'seventh',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'ACTION',
                              fieldFor: 'eighth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                          ]
                        : [
                            {
                              headerName: 'DUE DATE',
                              fieldFor: 'first',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                          
                            {
                              headerName: 'PARENT COMPANY',
                              fieldFor: 'second',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DEAL NAME / DEAL ID',
                              fieldFor: 'third',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'DOCUMENT TYPE',
                              fieldFor: 'fourth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'AS OF DATE',
                              fieldFor: 'fifth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'STATUS',
                              fieldFor: 'sixth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                            {
                              headerName: 'ACTION',
                              fieldFor: 'eighth',
                              isSortable: false,
                              wrapContent: true,
                              dataType: 'node',
                              sortValues: ['Oldest', 'Newest'],
                              headerAlignment: 'left',
                            },
                          ]
                    }
                    tableData={
                      reportResults.length != 0
                        ? paginationtableData(
                            reportResults,
                            activeFilters.status
                          )
                        : [
                            {
                              first: '-',
                              second: '-',
                              third: '-',
                              fourth: '-',
                              fifth: '-',
                              sixth: '-',
                              seventh: '',
                              eighth: '',
                            },
                          ]
                    }
                  />
                )}
          </div>
        </USBColumn>
      </USBGrid>
    </>
  );
};

export default ClosingBinderPortfolio;
