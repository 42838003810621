import React, { useEffect, useState } from 'react';
import { USBButton } from '@usb-shield/components-react';
import axios from 'axios';
import { endpoints } from '../../../config/connections';
import './Termsofuse.css';
import rateLimit from 'axios-rate-limit';
import Axios from 'axios';
import GraphQlQuery from '../../utilities/graphQL-query';
import { getSvcServiceURL, getAuthServiceURL } from '../../config/connections';

const Termsofuse = () => {
  const [apiResponse, setApiResponse] = useState(0);
  const [apiStatus, setApiStatus] = useState({accept : false, user : false, activation : false, login : false})

  useEffect(()=>{
    if(apiStatus.accept && apiStatus.activation && apiStatus.user && apiStatus.login){
      window.location.href = '/Redirect';
    }
  },[apiStatus])

  const getTermsContent = () => {
    const termsUrl = `${endpoints.termsofuse}/en-us/esd/docmanagement/cdc-termsandconditions.model.json`;

    fetch(termsUrl)
      .then((response) => response.json())
      .then((res) => {
        // console.log('Response:: ', res[':items'].root[':items'].contentfragment.elements.termsandconditions.value)
        setApiResponse(
          res[':items'].root[':items'].contentfragment.elements
            .termsandconditions.value
        );
      });
  };

  const updateLastLogin = async () => {
    let apiURLFirst = '';
    apiURLFirst = `${endpoints.apigee}/document-management/cdc/requirements/v1/update/login`;
    const allData = [];
    const https = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )} ${sessionStorage.getItem('correlationId')}`,
          'Client-Application': 'CDC',
          'user-id':`${sessionStorage.getItem('loggedUser')}`,
          'jwt':`${sessionStorage.getItem('tok')}`,
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
        },
      }),
      { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
    );

   await axios
      .all([https.get(apiURLFirst)])
      .then(
        axios.spread((...response) => {
          setApiStatus((prevApiResponse) => {
            return {...prevApiResponse, login : true}
          })
        })
      )
      .catch((e) => {
        setApiStatus((prevApiResponse) => {
          return {...prevApiResponse, login : true}
        })
        console.log('DC651:', e);
      });
  }

  const activateUser = async (user) => {
    // console.log(user)
    let apiURLFirst = '';
    apiURLFirst = `${endpoints.apigee}/document-management/coreservice/user-management/v1/user/activation`;
    const allData = [];
    // const tmp = 'https://it1-usb-digital-api-common.us.bank-dns.com/document-management/coreservice/user-management/v1/user/activation'
    const https = rateLimit(
      axios.create({
        headers: {
          Accept: 'application/json',
          'Correlation-ID': `${sessionStorage.getItem(
            'loggedUser'
          )} ${sessionStorage.getItem('correlationId')}`,
          'Client-Application': 'CDC',
          'user-id':`${sessionStorage.getItem('loggedUser')}`,
          'jwt':`${sessionStorage.getItem('tok')}`,
          Authorization: `Bearer ${sessionStorage.getItem('at')}`,
        },
      }),
      { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
    );
    const activationBody = {
      "requestType": {
          "serviceType": "CDC_PROF_MAINT",
          "serviceSubType": "EDIT_PROFILE"
      },
      "data": {
          "intent": "EDIT_PROFILE",
          "profileID": user.profileID,
          "userID": user.userID,
          "firstName":  user.firstName,
          "lastName":  user.lastName,
          "middleName":  user.middleName,
          "email": user.email,
          "parentEIN":  user.parentEIN,
          "parentCompanyName":  user.parentCompanyName,
          "parentCompanyLegalEntityID":  user.parentCompanyLegalEntityID,
          "profileRole":  user.profileRole,
          "address" : user.address,
          "phoneNumbers" : user.phoneNumbers,
          "status":  'Active',
      }
  }
   await https.post(apiURLFirst,activationBody).then((response) => {
    setApiStatus((prevApiResponse) => {
      return {...prevApiResponse, activation : true}
    })
  })
  .catch((error) => {
    setApiStatus((prevApiResponse) => {
      return {...prevApiResponse, activation : true}
    })
  });
  }

  const userDetails = async () => {
    const graphURL = getSvcServiceURL({ service: 'graph' });
    const query = GraphQlQuery.getProfileByUserId(sessionStorage.getItem('loggedUser'));
    Axios.post(graphURL, query, { cdcType: 'servicing' })
      .then(async (response) => {
        const data = response.data;
        if (
          data &&
          data.data &&
          data.data.getProfileList &&
          data.data.getProfileList.profiles &&
          data.data.getProfileList.profiles[0]
        ) {
          setApiStatus((prevApiResponse) => {
            return {...prevApiResponse, user : true}
          })
          await activateUser(data.data.getProfileList.profiles[0]);
        }
      })
      .catch((error) => {
        setApiStatus((prevApiResponse) => {
          return {...prevApiResponse, user : true}
        })
      })
    // const getCDCPermissionURL = `${endpoints.apigee}/document-management/coreservice/user-management/v1/users/userId`;
    //     const https = rateLimit(
    //         axios.create({
    //             headers: {
    //                 Accept: 'application/json',
    //                 'Correlation-ID': `${sessionStorage.getItem(
    //                     'loggedUser'
    //                 )} ${sessionStorage.getItem('correlationId')}`,
    //                 'Client-Application': 'CDC',
    //                 'user-id': sessionStorage.getItem('loggedUser'),
    //                 'jwt': `${sessionStorage.getItem('tok')}`,
    //                 Authorization: `Bearer ${sessionStorage.getItem('at')}`,
    //             },
    //         }),
    //         { maxRequests: 7, perMilliseconds: 1000, maxRPS: 7 }
    //     );

    //      axios
    //         .all([https.get(getCDCPermissionURL)])
    //         .then(
    //             async (response) => { await activateUser(response[0])}
    //         )
    //         .catch((e) => {
    //             console.log('DC651:', e);
    //         });
  }

  const acceptTerms = async () => {
    const jwtDSID = sessionStorage.getItem('tok');
    const accessToken = sessionStorage.getItem('at');
    const userId = sessionStorage.getItem('loggedUser');
    const roleId = sessionStorage.getItem('role');
    const token = sessionStorage.getItem('tok');
    await userDetails()
    await updateLastLogin();

    const header = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      routingKey: endpoints.routingUpdateKey,
      'Correlation-ID': `${jwtDSID.sid}`,
      'Client-Data': `{"Role-ID": "${roleId}", "User-ID": "${userId}", "App-ID": "CDC", "Tenant-ID": "USB", "App-Version": "1", "Channel-ID": "web"}`,
      Accept: 'application/json',
      'Client-ID': 'CDC',
      'Service-Version': '2',
      SessionToken: token,
      Aid: 'cdc_web',
    };
    // const userId = sessionStorage.getItem('loggedUser')
    const accepturl = `${endpoints.apigee}/document-management/coreservice/user-management/v1/cdcservice`;
    const payload = {
      requestType: {
        serviceType: 'CDC_PROF_MAINT',
        serviceSubType: 'CREATE_TNC',
      },
      data: {
        intent: 'CREATE_TNC',
        userID: userId,
        productID: 'cdc-termsandconditions',
        sourceType: 'CDC',
      },
    };
    await axios({
      method: 'post',
      url: accepturl,
      data: payload,
      headers: header,
    })
      .then( (response) => {
        setApiStatus((prevApiResponse) => {
          return {...prevApiResponse, accept : true}
        })
      })
      .catch(async (error) => {
        setApiStatus((prevApiResponse) => {
          return {...prevApiResponse, accept : true}
        })
      });
  };

  const declineTerms = () => {
    window.location = '/';
  };

  useEffect(() => {
    getTermsContent();
  }, []);

  // https://stackoverflow.com/questions/39758136/render-html-string-as-real-html-in-a-react-component
  return (
    <>
      <div className="tou">
        <div className="tou-title">Terms of use</div>
        <div
          className="tou-container"
          dangerouslySetInnerHTML={{ __html: apiResponse }}
        />
        <div className="tou-button">
          <div className="single-buttons">
            <USBButton
              addClasses="decline-button"
              handleClick={() => declineTerms()}
            >
              Decline
            </USBButton>
          </div>
          <div className="single-buttons">
            <USBButton variant="primary" handleClick={acceptTerms}>
              Accept
            </USBButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Termsofuse;